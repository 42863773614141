import React, { PropsWithChildren } from 'react'
import { Close } from '@mui/icons-material'
import { Dialog as MuiDialog, IconButton, DialogTitle, DialogContent, Typography, Box, CircularProgress, useMediaQuery } from '@mui/material'
import { theme } from '~/utils'

export type DialogProps = {
  onClose?: () => void
  isVisible: boolean
  minWidth?: string | number
  title?: string
  titleIcon?: React.JSX.Element
  loading?: boolean
}

type StyledDialogTitleProps = {
  title?: string
  onClose?: () => void,
  titleIcon?: React.JSX.Element
}
const StyledDialogTitle = (props: StyledDialogTitleProps) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { title, onClose, titleIcon, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <Box display='flex' gap={1} alignItems='center'>
        {titleIcon}
        <Typography fontSize={isLowerSm ? '1rem' : '1.5rem'} variant='h3' fontWeight={500}>{title}</Typography>
      </Box>
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: () => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export const Dialog: React.FC<PropsWithChildren<DialogProps>> = (props) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { isVisible, onClose, minWidth, children, title, titleIcon, loading } = props

  return (
    <MuiDialog
      sx={{ padding: isLowerSm ? '0 1rem' : '0' }}
      PaperProps={{
        style: {
          borderRadius: 16,
          padding: 4,
          marginTop: 3,
          display: 'flex',
          flexDirection: 'column',
          margin: '0 1rem',
          minWidth: isLowerSm ? '100%' : minWidth || '560px'
        }
      }}
      open={isVisible}
    >
      <StyledDialogTitle onClose={onClose} title={title} titleIcon={titleIcon} />
      <DialogContent sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        {loading ? <CircularProgress size='50px' sx={{ alignSelf: 'center', margin: 3 }} /> : <>{children}</>}
      </DialogContent>

    </MuiDialog>
  )
}
