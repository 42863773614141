/* eslint-disable no-unused-vars */
import { transparentize } from 'polished'
import { EContractStatus, EDocumentSignStatus, EInvoiceStatus, EProposalStatus, ETermStatus } from '~/graphql/types'
import { EDigitalAccountStatus } from './translate'

type StatusColor = {
  background: string,
  color: string
}

const PROPOSAL_STATUS_COLORS: { [x in EProposalStatus]: StatusColor } = {
  formalization: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  finalized: {
    color: '#367154',
    background: '#C6F6D5'
  },
  documentation: {
    color: '#3591E5',
    background: transparentize('0.75', '#3591E5')
  },
  canceled: {
    color: '#BD3535',
    background: 'rgba(239, 68, 68, 0.1)'
  }
}

export function colorProposalStatus(status?: EProposalStatus) {
  if (!status) return {
    background: 'transparent',
    color: 'transparent'
  }
  return PROPOSAL_STATUS_COLORS[status]
}

const DIGITAL_ACCOUNT_STATUS_COLORS: { [x in EDigitalAccountStatus]: StatusColor } = {
  inactive: {
    color: '#999999',
    background: '#E6E6E6'
  },
  approved: {
    color: '#367154',
    background: '#C6F6D5'
  },
  pending: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  canceled: {
    color: '#BD3535',
    background: 'rgba(239, 68, 68, 0.1)'
  },
  blocked: {
    color: '#BD3535',
    background: 'rgba(239, 68, 68, 0.1)'
  },
  disapproved: {
    color: '#BD3535',
    background: 'rgba(239, 68, 68, 0.1)'
  },
  inAnalysis: {
    color: '#3591E5',
    background: transparentize('0.75', '#3591E5')
  }
}

export function colorDigitalAccount(status?: EDigitalAccountStatus) {
  if (!status) return {
    background: 'transparent',
    color: 'transparent'
  }
  return DIGITAL_ACCOUNT_STATUS_COLORS[status]
}

const CONTRACT_STATUS_COLORS: { [x in EContractStatus]: StatusColor } = {
  cancellationRequested: {
    color: '#703636',
    background: '#F5C4C4'
  },
  deactivated: {
    color: '#999999',
    background: '#E6E6E6'
  },
  inDeactivation: {
    color: '#AD6801',
    background: '#FDD9A4'
  },
  readyToSend: {
    color: '#3BA1FF',
    background: '#DBF1FE'
  },
  reserved: {
    color: '#561885',
    background: '#EFD7FF'
  },
  sent: {
    color: '#31903B',
    background: '#DBFEE3'
  },
  active: {
    color: '#31903B',
    background: '#DBFEE3'
  },
  signatures: {
    color: '#A16F31',
    background: '#FEFCBF'
  }
}

export const colorContractStatus = (status?: EContractStatus): StatusColor => {
  if (!status) {
    return {
      color: 'transparent',
      background: 'transparent'
    }
  }

  return CONTRACT_STATUS_COLORS[status]
}

const CUSTOMER_STATUS_COLORS: { [x in string]: StatusColor } = {
  pending: {
    color: 'rgba(153, 153, 153, 1)',
    background: 'rgba(230, 230, 230, 1)'
  },
  completed: {
    color: '#367154',
    background: '#C6F6D5'
  },
  rejected: {
    color: '#BD3535',
    background: 'rgba(239, 68, 68, 0.1)'
  }
}

export const colorCustomerStatus = (status?: string) => {
  if (!status) return {
    background: 'transparent',
    color: 'transparent'
  }
  return CUSTOMER_STATUS_COLORS[status]
}

const TERM_STATUS_COLORS: { [x in ETermStatus]: StatusColor } = {
  canceled: {
    color: '#BD3535',
    background: 'rgba(239, 68, 68, 0.1)'
  },
  refused: {
    color: '#BD3535',
    background: 'rgba(239, 68, 68, 0.1)'
  },
  uploaded: {
    color: '#3BA1FF',
    background: '#DBF1FE'
  },
  signed: {
    color: '#367154',
    background: '#C6F6D5'
  },
  replaced: {
    color: '#999999',
    background: '#E6E6E6'
  },
  requestedSignature: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  created: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  expired: {
    color: '#999999',
    background: '#E6E6E6'
  }
}
export const termStatusColors = (status?: ETermStatus) => {
  if (!status) return {
    background: 'transparent',
    color: 'transparent'
  }
  return TERM_STATUS_COLORS[status]
}

const DOCUMENT_SIGN_STATUS_COLORS: { [x in EDocumentSignStatus]: StatusColor } = {
  refused: {
    color: '#BD3535',
    background: 'rgba(239, 68, 68, 0.1)'
  },
  uploaded: {
    color: '#3BA1FF',
    background: '#DBF1FE'
  },
  signed: {
    color: '#367154',
    background: '#C6F6D5'
  },
  pending: {
    color: '#999999',
    background: '#E6E6E6'
  },
  signatureRequested: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  created: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  expired: {
    color: '#999999',
    background: '#E6E6E6'
  },
  error: {
    color: '#BD3535',
    background: 'rgba(239, 68, 68, 0.1)'
  }
}

export const documentSignStatusColors = (status?: EDocumentSignStatus) => {
  if (!status) return {
    background: 'transparent',
    color: 'transparent'
  }
  return DOCUMENT_SIGN_STATUS_COLORS[status]
}

const CONTRACT_INVOICE_COLORS: { [x in EInvoiceStatus]: StatusColor } = {
  pending: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  requested: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  paid: {
    color: '#367154',
    background: '#C6F6D5'
  },
  late: {
    color: '#BD3535',
    background: 'rgba(239, 68, 68, 0.1)'
  },
  canceled: {
    color: '#999999',
    background: '#E6E6E6'
  },
  expired: {
    color: '#999999',
    background: '#E6E6E6'
  },
  paymentRequest: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  createPayment: {
    color: '#999999',
    background: '#E6E6E6'
  },
  generatePDF: {
    color: '#999999',
    background: '#E6E6E6'
  }

}

export function colorContractInvoice(status?: EInvoiceStatus) {
  if (!status) return {
    background: 'transparent',
    color: 'transparent'
  }
  return CONTRACT_INVOICE_COLORS[status]
}
