import { EmailOutlined } from '@mui/icons-material'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { useCallback } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Input } from '~/components/Form/Input'
import { useUserChangePasswordMutation, useUserRecoverPasswordMutation } from '~/graphql/types'
import { LinkWithNoStyle } from '~/styles'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { RequirementsRecover } from './styles'
import { IconCircleFilled } from '@tabler/icons-react'
import { passwordRegex, theme } from '~/utils'

type RecoverPasswordFormData = {
  email: string
  newPassword: string,
  retypeNewPassword: string
}

type RecoverPasswordFormProps = {
  token: string | null
}

const schemaRecoverPassword = yup
  .object({
    email: yup.string().email().required(),
  })
  .required()

const schemaChangePassword = yup
  .object({
    newPassword: yup.string()
      .required()
      .min(6)
      .matches(passwordRegex.hasUppercase, 'Pelo menos 1 carácter maiúsculo')
      .matches(passwordRegex.hasLowercase, 'Pelo menos 1 carácter minúsculo')
      .matches(passwordRegex.hasNumeric, 'Pelo menos 1 carácter numérico')
      .matches(passwordRegex.hasSpecialCharacter, 'Pelo menos 1 carácter especial')
    ,
    retypeNewPassword: yup.string().oneOf([yup.ref('newPassword')], 'O campo confirmação de senha deve ter o mesmo valor do campo senha.').required(),
  })
  .required()

export const RecoverPasswordForm: React.FC<RecoverPasswordFormProps> = ({ token }) => {

  const formMethods = useForm<RecoverPasswordFormData>({
    resolver: yupResolver(token ? schemaChangePassword : schemaRecoverPassword)
  })

  const navigate = useNavigate()

  const [userRecoverPassword, { loading: userRecoverPasswordIsLoading }] = useUserRecoverPasswordMutation({
    onCompleted: () => {
      toast.success('Um link com a recuperação de senha foi enviado ao seu email!')
    },
    onError: () => {
      toast.error('Não encontramos seu E-mail :(')
    }
  })

  const [userChangePassword, { loading: userChangePasswordIsLoading }] = useUserChangePasswordMutation({
    onCompleted: () => {
      toast.success('Sua senha foi alterada!')
      navigate('/login')
    },
    onError: () => {
      toast.error('Não foi possível alterar sua senha :(')
    }
  })

  const handleSubmit = useCallback((data: RecoverPasswordFormData) => {
    if(token) {
      userChangePassword({
        variables: {
          params: {
            newPassword: data.newPassword,
            retypeNewPassword: data.retypeNewPassword,
            token: token || ''

          }
        }
      })
    } else {
      userRecoverPassword({
        variables: {
          params: {
            email: data.email
          }
        }
      })
    }

  }, [token])

  const { newPassword } = useWatch({ control: formMethods.control })

  const validatePassword = {
    hasSixDigits: Boolean(newPassword && newPassword?.length >= 6),
    hasUppercase: Boolean(newPassword?.match(passwordRegex?.hasUppercase)),
    hasLowercase: Boolean(newPassword?.match(passwordRegex?.hasLowercase)),
    hasNumeric: Boolean(newPassword?.match(passwordRegex?.hasNumeric)),
    hasSpecialCharacter: Boolean(newPassword?.match(passwordRegex?.hasSpecialCharacter)),
  }

  return (
    <FormProvider {...formMethods}>
      <form style={{ width: '100%', display: 'flex', flexDirection: 'column' }} onSubmit={formMethods.handleSubmit((data) => handleSubmit(data))}>

        {token ? (
          <Box sx={{ display: 'flex', flexFlow: 'column', gap: '1rem' }}>
            <Input 
              label='Nova senha'
              name='newPassword'
              type='password'
            />

            <RequirementsRecover>
              <li><IconCircleFilled size={12} style={{ color: validatePassword.hasLowercase ? '#9E78BC' : theme.palette.grey[300] }} /><span>Pelo menos 1 caractere minúsculo</span></li>
              <li><IconCircleFilled size={12} style={{ color: validatePassword.hasUppercase ? '#9E78BC' : theme.palette.grey[300] }} /><span>Pelo menos 1 caractere maiúsculo</span></li>
              <li><IconCircleFilled size={12} style={{ color: validatePassword.hasNumeric ? '#9E78BC' : theme.palette.grey[300] }} /><span>Pelo menos 1 número</span></li>
              <li><IconCircleFilled size={12} style={{ color: validatePassword.hasSpecialCharacter ? '#9E78BC' : theme.palette.grey[300] }} /><span>Pelo menos 1 caractere especial</span></li>
              <li><IconCircleFilled size={12} style={{ color: validatePassword.hasSixDigits ? '#9E78BC' : theme.palette.grey[300] }} /><span>Mínimo de 6 caracteres</span></li>
            </RequirementsRecover>

            <Input 
              label='Confirme a nova senha' 
              name='retypeNewPassword'
              type='password'
            />

          </Box>

        ) : (
          
          <Input 
            label='Email' 
            name='email' 
            icons={{ start: { element: <EmailOutlined style={{ color: '#999999' }} />, background: '#FAFAFA' } }} 
          />

        )}

        <Box sx={{ display: 'flex',justifyContent: 'center',padding: '1rem 0 2rem 0' }}>
          <LinkWithNoStyle to='/login'>
            <Typography variant='body2'>Voltar ao login</Typography>
          </LinkWithNoStyle>
        </Box>

        <Button
          disabled={userRecoverPasswordIsLoading || userChangePasswordIsLoading}
          style={{ fontSize: '16px', fontWeight: 600 }}
          variant='contained'
          color='primary'
          type='submit'
          endIcon={userRecoverPasswordIsLoading || userChangePasswordIsLoading && <CircularProgress size={24} />}
        >
          {token ? 'Concluir redefinição' : 'Receber link para redefinir senha'}
        </Button>
      </form>
    </FormProvider>
  )
}
