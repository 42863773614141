import './utils/yup'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import * as client_1 from '@apollo/client'
import { client } from './services/graphql'
import * as Sentry from '@sentry/react'
import { theme } from './utils/theme'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './components/ErrorFallback'
import { ProposalProvider, SidebarProvider } from './contexts'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from '~/contexts'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { clarity } from 'react-microsoft-clarity'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DNS,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', /^https:\/\/alpha.backoffice.despertaenergia.\.com/, /^https:\/\/backoffice.despertaenergia.\.com/]
    }),
    // new Sentry.Replay()
  ],
  release: 'salesforce@1.0.2',
  tracesSampleRate: 1.0,
  environment: import.meta.env.VITE_APP_ENV
})

if (import.meta.env.VITE_APP_ENV === 'production') {
  clarity.init('nbfwrmrxh5')
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <client_1.ApolloProvider client={client}>
      <StyledEngineProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <AuthProvider>
              <ProposalProvider>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <SidebarProvider>
                    <App />
                    <ToastContainer />
                  </SidebarProvider>
                </ErrorBoundary>
              </ProposalProvider>
            </AuthProvider>
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </client_1.ApolloProvider>
  </React.StrictMode>,
)
