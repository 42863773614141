/* eslint-disable indent */
import { Box, Button, Card, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material'
import { IconArrowRight, IconPlus, IconSearch, IconTrash } from '@tabler/icons-react'
import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Column, useFilters, usePagination, useSortBy, useTable } from 'react-table'
import { DigitalAccountStatus, Status, TableApiV2, TableHeaderApi } from '~/components'
import { ContentTitle } from '~/components/ContentTitle'
import { useConsortiumListQuery } from '~/graphql/types'
import { useDebounce, usePaginationValues, usePermission } from '~/hooks'
import { displayCpfCnpj, ifIsDocumentReturnFormated, theme } from '~/utils'

export const ConsortiumList: React.FC = () => {
  const navigate = useNavigate()
  const canCreateAndEditConsortiums = usePermission(['consortiums.updated', 'consortiums.create'])
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const [searchParams, setSearchParams] = useSearchParams()
  const { page, size } = usePaginationValues()
  const name = searchParams.get('name')
  const [filterName, setFilterName] = useState(name || '')
  const debouncedFilterName = useDebounce(filterName, 1000)
  const { data: consortiums, loading: consortiumListIsLoading } = useConsortiumListQuery({
    variables: {
      params: {
        pageSize: size,
        pageNumber: page,
        sort: {
          field: 'createdAt',
          order: -1
        },
        ...name && name?.length > 0 ? { nameOrFantasyNameOrDocument: ifIsDocumentReturnFormated(name) } : {},
      }
    },
    onCompleted(result) {
      setSearchParams(state => {
        state.set('items', String(result.consortiumList.meta.documentCounts))
        return state
      })
    },
  })
  const handleFilters = (filters: { name: string }) => {
    setSearchParams(state => {
      if (filters.name) {
        state.set('name', filters.name)
        state.set('page', '0')
      } else {
        state.delete('name')
      }
      return state
    })
  }

  useEffect(() => {
    handleFilters({ name: debouncedFilterName })
  }, [debouncedFilterName])

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
          <>
            {value === 'active' ?
              <Status text='Ativo' background={theme.palette.success.light} color={theme.palette.success.main} />
              : <Status text='Inativo' color={theme.palette.error.main} />
            }
          </>
        )
      },
      {
        // ESPERAR RETORNO DA IUGU DO STATUS PARA CONFIRMAR COMO SERA PREENCHIDO
        Header: 'Status da conta digital',
        accessor: 'digitalAccount.status',
        Cell: ({ value }) => (
          <>
            <DigitalAccountStatus status={value} />
          </>
        )
      },
      {
        Header: 'CNPJ',
        accessor: 'cnpj',
        Cell: ({ value }) => <>{displayCpfCnpj(value)}</>
      },
      {
        Header: 'Acessar',
        accessor: '_id',
        disableSortBy: true,
        Cell: ({ value }) => (
          <>
            {canCreateAndEditConsortiums ? (
              <Link to={`/app/consortiums/update/${value}`}>
                <IconButton>
                  <IconArrowRight />
                </IconButton>
              </Link>
            ) : (
              <IconButton disabled={true}>
                <IconArrowRight />
              </IconButton>
            )}
          </>
        )
        ,
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => consortiums?.consortiumList.data || [], [consortiums])

  const defaultColumnHiddens = isLowerMd ? ['status'].concat(isLowerSm ? ['cnpj'] : '') : []

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    manualPagination: true,
    initialState: {
      hiddenColumns: defaultColumnHiddens,
    },
    defaultColumn: {
      Filter: <></>
    },
  },
    useFilters,
    useSortBy,
    usePagination,
  )

  return (
    <div>
      <Card sx={{ background: 'white', padding: '0 1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title='Lista de Consórcios'
          description='Consulte todos consórcios cadastrados'
          breadcrumbLinks={{ currentLink: 'Listar consórcios', links: [{ href: '/app/consortiums', label: 'Consórcios' }] }}
          rightContent={<Button disabled={!canCreateAndEditConsortiums} onClick={() => navigate('/app/consortiums/create')} startIcon={<IconPlus />}>Cadastrar consórcio</Button>}
        />
        <TableHeaderApi
          startElement={(
            <>
              <TextField
                fullWidth
                sx={{ maxWidth: '400px' }}
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                placeholder='Procurar consórcio por nome ou CPF/CNPJ'
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position='end'>
                        <IconSearch />
                      </InputAdornment>
                    </>
                  )
                }}
              />
            </>
          )}
          endElement={
            (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                {tableMethods.selectedFlatRows && tableMethods.selectedFlatRows.length !== 0 &&
                  (
                    <>
                      <Typography color='grey.600'>{tableMethods.selectedFlatRows.length} Selecionado(s)</Typography>
                      <Button sx={{ fontWeight: 600 }} size='small' startIcon={<IconTrash></IconTrash>} color='error'>Excluir</Button>
                    </>
                  )
                }
              </Box>
            )
          }
        />

        <TableApiV2
          isLoading={consortiumListIsLoading}
          tableMethods={tableMethods}
          columns={columns}
        />

      </Card>
    </div>
  )
}
