import styled from '@emotion/styled'
import { theme } from '~/utils'

export const RequirementsRecover = styled.div`
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: .7rem 0;
  padding: .6rem 0;
  @media(max-width: ${theme.breakpoints.values.sm}px) {
    grid-template-columns: 1fr;
    gap: 1rem 0;
  }
  li {
    display: flex;
    align-items: center;
    gap: .2rem;
    font-size: 11px;
    color: ${(props) => props.theme.palette.grey[600]};
    span {
      line-height: 12px;
    }
  }
`
