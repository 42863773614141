import { Box, Link, Typography, useTheme } from '@mui/material'
import { Info } from '@mui/icons-material'
import { PropsWithWizard } from '~/components'

export const NotSelectedConsortium: React.FC<PropsWithWizard> = ({ goToPage }) => {
  const theme = useTheme()
  return (
    <Box sx={{
      gap: 3,
      padding: 3,
      display: 'flex',
      borderRadius: '12px',
      alignItems: 'center',
      flexDirection: 'row',
      border: '1px solid #F3CC04'
    }}>
      <Info fontSize='large' color='primary' />
      <Typography>
        A proposta não pode ser formalizada porque o consórcio não foi definido. Defina o consórcio <Link onClick={() => goToPage(0)} color={theme.palette.info.dark} fontWeight={500} sx={{ cursor: 'pointer' }}>AQUI</Link>
      </Typography>
    </Box>
  )
}
