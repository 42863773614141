import { ECustomerPersonType, EProposalFileStatus, EProposalFileType, ProposalCustomerPendenciesFields, ProposalLegalRepresentativePendenciesFields, ProposalModel } from '~/graphql/types'

export const isPendencyField = (fieldName: keyof ProposalCustomerPendenciesFields, pendencies?: ProposalCustomerPendenciesFields | null) => {
  if (!pendencies) {
    return false
  }

  const pendencyFields = Object.entries(pendencies).filter(pendency => pendency[1].rejectedAt && !pendency[1].acceptedAt).map(pendency => pendency[0])

  return pendencyFields.includes(fieldName)
}

export const isLegalRepresentativePendencyField = (fieldName: keyof ProposalLegalRepresentativePendenciesFields, pendencies?: ProposalLegalRepresentativePendenciesFields | null) => {
  if (!pendencies) {
    return false
  }

  const pendencyFields = Object.entries(pendencies).filter(pendency => pendency[1].rejectedAt && !pendency[1].acceptedAt).map(pendency => pendency[0])

  return pendencyFields.includes(fieldName)
}

const hasAllDocuments = (proposal: ProposalModel | null) => {
  if (!proposal) return false

  const { files, customerRef } = proposal
  const { personType } = customerRef

  const hasEnergyBillDocument = Boolean(files?.find(item => item.type === EProposalFileType.energyBill)?.status === EProposalFileStatus.accepted)
  if (personType === ECustomerPersonType.pf) {
    const hasFrontCustomerDocument = Boolean(files?.find(item => item.type === EProposalFileType.customerPfDocumentWithPhotoFront)?.status === EProposalFileStatus.accepted)
    const hasBackCustomerDocument = Boolean(files?.find(item => item.type === EProposalFileType.customerPfDocumentWithPhotoBack)?.status === EProposalFileStatus.accepted)

    return Boolean(hasFrontCustomerDocument && hasBackCustomerDocument && hasEnergyBillDocument)
  }

  const hasCustomerPjCardDocument = Boolean(files?.find(item => item.type === EProposalFileType.customerPjDocumentCard)?.status === EProposalFileStatus.accepted)
  const hasSocialContractDocument = Boolean(files?.find(item => item.type === EProposalFileType.customerPjSocialContract)?.status === EProposalFileStatus.accepted)
  
  return Boolean(hasCustomerPjCardDocument && hasSocialContractDocument && hasEnergyBillDocument)
}

const hasSolvedLegalRepresentativesPendencies = (proposal: ProposalModel | null) => {
  if (!proposal) return false

  const { customerRef } = proposal
  const { legalRepresentative, personType } = customerRef

  if (personType === ECustomerPersonType.pf) {
    return true
  }

  return Boolean(
    legalRepresentative?.every(item => {
      const { pendencies, files } = item
      const backDocument = files?.find(document => document.type === EProposalFileType.customerPjRepresentativeDocumentWithPhotoBack)
      const frontDocument = files?.find(document => document.type === EProposalFileType.customerPjRepresentativeDocumentWithPhotoFront)

      return Boolean(
        pendencies?.city.acceptedAt &&
        pendencies.name.acceptedAt &&
        pendencies.email.acceptedAt &&
        pendencies.phone.acceptedAt &&
        pendencies.state.acceptedAt &&
        pendencies.number.acceptedAt &&
        pendencies.zipcode.acceptedAt &&
        pendencies.document.acceptedAt &&
        pendencies.neighborhood.acceptedAt &&
        pendencies.maritalStatus.acceptedAt &&
        backDocument?.status === EProposalFileStatus.accepted &&
        frontDocument?.status === EProposalFileStatus.accepted
      )
    })
  )
}

export const getCanFormalizeProposal = (proposal: ProposalModel | null, isStepFormalization: boolean) => {
  if (!proposal) return false

  const { customerRef } = proposal
  const { pendencies, personType } = customerRef

  const basePendencies = pendencies && Boolean(
    pendencies.uf.acceptedAt &&
    pendencies.name.acceptedAt &&
    pendencies.city.acceptedAt &&
    pendencies.email.acceptedAt &&
    pendencies.phone.acceptedAt &&
    pendencies.state.acceptedAt &&
    pendencies.street.acceptedAt &&
    pendencies.number.acceptedAt &&
    pendencies.zipcode.acceptedAt &&
    pendencies.document.acceptedAt &&
    pendencies.neighborhood.acceptedAt &&
    pendencies.dealershipName.acceptedAt &&
    pendencies.averageEnergyBillValue.acceptedAt
  )

  const pfPendencies = pendencies && Boolean(
    pendencies.nationality.acceptedAt &&
    pendencies.maritalStatus.acceptedAt
  )

  const hasSelectedConsortium = isStepFormalization ? Boolean(proposal.consortiumId !== null) : true 

  const allPendenciesSolved = personType === ECustomerPersonType.pf ? Boolean(basePendencies && pfPendencies) : basePendencies
  
  return Boolean(
    allPendenciesSolved &&
    hasAllDocuments(proposal) &&
    hasSolvedLegalRepresentativesPendencies(proposal) &&
    hasSelectedConsortium
  )
}
