import {  useCallback, useEffect, useRef, useState } from 'react'
import ReactCrop, { centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import { Box, Button, Divider, Slider } from '@mui/material'
import { uploadToS3 } from '~/services/uploadFile'
import { toast } from 'react-toastify'

import 'react-image-crop/dist/ReactCrop.css'
import { canvasPreview } from './canPreview'
import { useDebounceEffect } from './useDebounceEffect'
import { CropperContainer } from './styles'
import { Dialog } from '~/components'
import { NoSelectInputFile } from './components'
import { EStorageService, FileUploadInputTypeDto } from '~/graphql/types'
import DespertaLogo from '~/assets/images/logo/desperta-logo.svg'
import DespertaLogoReduced from '~/assets/images/logo/desperta-logo-reduced.svg'
import { Input } from '~/components/Form/Input'
import { useFormContext } from 'react-hook-form'
import { FormData } from '../..'
import { useLocation, useParams } from 'react-router-dom'

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

export type PictureLogoType = 'defaultLogo' | 'reducedLogo'

type PictureCropedProps = {
  typeLogo: PictureLogoType
  partnerLogo: string | null
}

export const PictureCroped: React.FC<PictureCropedProps> = ({ typeLogo, partnerLogo }) => {
  const { id } = useParams()
  const pathname = useLocation()
  const [regularLogo, setRegularLogo] = useState(DespertaLogo)
  const [reducedLogo, setReducedLogo] = useState(DespertaLogoReduced)
  const [uploadImageIsLoading, setUploadImageIsLoading] = useState(false)
  const [imgSrc, setImgSrc] = useState<null | string>(null)
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const [scale, setScale] = useState(1)
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [fileCropped, setFileCropped] = useState<File | null>(null)
  const [crop, setCrop] = useState<Crop | undefined>({
    unit: '%',
    x: 25,
    y: 25,
    width: 50,
    height: 50
  })

  useEffect(() => {
    if(partnerLogo && pathname.pathname.includes('update') && partnerLogo) {
      typeLogo === 'defaultLogo' ? setRegularLogo(partnerLogo) : setReducedLogo(partnerLogo)
    } else {
      setRegularLogo(DespertaLogo)
      setReducedLogo(DespertaLogoReduced)
    }
  }, [id])

  const { setValue, clearErrors } = useFormContext<FormData>()

  const aspectRatio = typeLogo === 'defaultLogo' ?  27/9 : 4/4

  const closeModal = useCallback(() => {
    setCompletedCrop(undefined)
    setImgSrc(null)
    setFileCropped(null)
  }, [])

  const uploadFile = async () => {
    setUploadImageIsLoading(true)
    try {
      const response = await uploadToS3([fileCropped as File])
      const formatedResponse: FileUploadInputTypeDto = {
        fileName: response.fileName,
        fileUrl: response.fileUrl,
        key: response.key,
        storageService: EStorageService.awsS3
      }
      if(typeLogo === 'defaultLogo') {
        setRegularLogo(croppedImageUrl || '')
        setValue('regularLogo', formatedResponse)
        clearErrors('regularLogo')
      } else {
        setReducedLogo(croppedImageUrl || '')
        setValue('reducedLogo', formatedResponse)
        clearErrors('reducedLogo')
      }
      closeModal()
      return response
    } catch (err) {
      toast.error('Houve um problema com um dos arquivos enviados')
    } finally {
      setUploadImageIsLoading(false)
    }
  }

  async function onDownloadCropClick() {
    const image = imgRef.current
    const previewCanvas = previewCanvasRef.current
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    )
    
    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    )

    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    })

    const convertFile = new File([blob], typeLogo === 'defaultLogo' ? `logo-partner.png` : `icone-partner.png`, {
      type: 'image/png'
    })
    const url = URL.createObjectURL(convertFile)
    setCroppedImageUrl(url)
    setFileCropped(convertFile)
    //caso queira usar blob
  }

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined)
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, aspectRatio))
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        await canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
        )
        onDownloadCropClick()
      }
    },
    100,
    [completedCrop, scale],
  )

  return (
    <div>
      {imgSrc ? (
        <>
          <Dialog minWidth='800px' isVisible={Boolean(imgSrc)} onClose={() => closeModal()}>
          
            <CropperContainer>
              <ReactCrop 
                crop={crop} 
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspectRatio}
              >
                <img
                  ref={imgRef}
                  alt='Crop me'
                  src={imgSrc}
                  style={{ transform: `scale(${scale})` }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </CropperContainer>
            <div>
              <div>
                <Slider
                  value={scale}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby='Zoom'
                  onChange={(e, myZoom) => setScale(myZoom as any)}
                />
              </div>
              <Divider sx={{ margin: '1rem 0 2rem 0' }} />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', paddingBottom: '1rem', justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => closeModal()}
                  variant='outlined'
                  color='secondary'
                >
                  Fechar
                </Button>
                <Button
                  disabled={uploadImageIsLoading}
                  onClick={() => uploadFile()}
                  variant='contained'
                  color='primary'
                >
                  Confirmar
                </Button>
              </Box>
            </div>
          </Dialog>
        </>
      ) : 
        <NoSelectInputFile regularLogo={regularLogo} reducedLogo={reducedLogo} typeLogo={typeLogo} accept='image/*' onSelectFile={onSelectFile} />
      }

      <Input type='hidden' sx={{ visibility: 'hidden' }} name={typeLogo === 'defaultLogo' ? 'regularLogo' : 'reducedLogo'} />
        
      {!!completedCrop && (
        <>
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop.width,
              height: completedCrop.height,
              position: 'fixed',
              right: '6000px'
            }}
          />
        </>
      )}
    </div>
  )
}
