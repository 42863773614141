import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Card, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import { useNavigate, useParams, Navigate, Link } from 'react-router-dom'
import { ContentTitle } from '~/components/ContentTitle'
import { Input } from '~/components/Form/Input'
import { ConsortiumDigitalAccountDocumentCreateDto, DigitalAccountPendecyDto, EBankAccountType, EConsortiumStatus, EDigitalAccountDocumentType, EDigitalAccountStatus, EStorageService, useConsortiumCreateMutation, useConsortiumGetQuery, useConsortiumUpdateMutation } from '~/graphql/types'
import { useForm, FormProvider, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useCallback, useEffect, useState } from 'react'
import { FullPageLoader } from '~/components/FullPageLoader'
import { clearString, theme } from '~/utils'
import { useCep, usePermission } from '~/hooks'
import { Select } from '~/components/Form/Select'
import { brazilStates } from '~/constants'
import * as yup from 'yup'
import { ConsortiumData } from '~/contexts/consortiums/types'
import { bankSchema, documentSchema, phoneSchema } from '~/utils/yupSchema'
import { IconCheck, IconExclamationCircle, IconSearch } from '@tabler/icons-react'
import { Radio } from '~/components/Form/Radio'
import { useBank } from '~/hooks/useBank'
import { bankAccountTypeOptions } from '~/utils/options'
import { Banner, DigitalAccountStatus } from '~/components'
import { DragAndDrop } from '~/components/Form/DragAndDrop'
import { ConsortiumFormData } from './types'
import { assembleFilesToUpload, fileIsRequiredByPendency, getStatus } from './utils'
import { uploadMultiToIuguWallet } from '~/services/uploadFile'

export const ConsortiumCreateEdit: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [selectedConsortium, setSelectedConsortium] = useState<ConsortiumData | null>(null)
  // const [selectedLegalRepresentativeDocType, setSelectedLegalRepresentativeDocType] = useState('')
  const canCreateAndEditConsortiums = usePermission(['consortiums.updated', 'consortiums.create'])
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const schema = yup
    .object({
      name: yup.string().required(),
      cnpj: documentSchema.required(),
      email: yup.string().email().required(),
      fantasyName: yup.string().required(),
      leaderEmail: yup.string().email().required(),
      leaderName: yup.string().required(),
      status: yup.string().required(),
      phone: phoneSchema,
      bank: bankSchema
        .when({
          is: () => {
            return Boolean(id && selectedConsortium)
          },
          then: (validationSchema) => validationSchema.nullable(),
          otherwise: (validationSchema) => validationSchema.required()
        }),

      legalRepresentative: yup.object().shape({
        name: yup.string().required(),
        document: documentSchema.required(),
      }).default(null)
        .when({
          is: () => {
            return Boolean(id && selectedConsortium)
          },
          then: (validationSchema) => validationSchema.nullable(),
          otherwise: (validationSchema) => validationSchema.required()
        }),

      address: yup.object().shape({
        city: yup.string().required(),
        country: yup.string().required(),
        neighborhood: yup.string().required(),
        number: yup.string().required(),
        state: yup.string().required(),
        street: yup.string().required(),
        zipcode: yup.string().required(),
        complement: yup.string()
      }).required(),

      balance_sheet: yup.array().min(fileIsRequiredByPendency(selectedConsortium?.digitalAccount?.pendencies.balance_sheet), 'O relatório financeiro é obrigatório').required(),
      social_contract: yup.array().min(fileIsRequiredByPendency(selectedConsortium?.digitalAccount?.pendencies.social_contract), 'O contrato social é obrigatório').required(),
      identificationFront: yup.array().min(fileIsRequiredByPendency(selectedConsortium?.digitalAccount?.pendencies.identificationFront), 'O documento (frente) é obrigatório').required(),
      identificationBack: yup.array().min(fileIsRequiredByPendency(selectedConsortium?.digitalAccount?.pendencies.identificationBack), 'O documento (verso) é obrigatório').required(),
      addressProof: yup.array().min(fileIsRequiredByPendency(selectedConsortium?.digitalAccount?.pendencies.addressProof), 'O comprovante de endereço é obrigatório').required(),
      selfie: yup.array().min(fileIsRequiredByPendency(selectedConsortium?.digitalAccount?.pendencies.selfie), 'A selfie é obrigatória').required(),

    })
    .required()

  const formMethods = useForm<ConsortiumFormData>({ resolver: yupResolver(schema) })

  const { address, bank } = useWatch({ control: formMethods.control })
  const { fetchCep, isLoading: fetchCepIsLoading } = useCep({ showErrorMessage: true })
  const { accountLabel, agencyLabel, bankOptions, agencyMask, accountMask } = useBank(bank?.code)

  const consortiumSelfieLegalRepresentative = selectedConsortium?.digitalAccount?.documents.find(item => item.type === EDigitalAccountDocumentType.selfie)
  const consortiumSocialContract = selectedConsortium?.digitalAccount?.documents.find(item => item.type === EDigitalAccountDocumentType.social_contract)
  const consortiumAddressProof = selectedConsortium?.digitalAccount?.documents.find(item => item.type === EDigitalAccountDocumentType.addressProof)
  const consortiumBalanceSheet = selectedConsortium?.digitalAccount?.documents.find(item => item.type === EDigitalAccountDocumentType.balance_sheet)
  const consortiumIdentificationFront = selectedConsortium?.digitalAccount?.documents.find(item => item.type === EDigitalAccountDocumentType.identificationFront)
  const consortiumIdentificationBack = selectedConsortium?.digitalAccount?.documents.find(item => item.type === EDigitalAccountDocumentType.identificationBack)

  const isDisabledField = selectedConsortium ? selectedConsortium?.digitalAccount?.status === EDigitalAccountStatus.inAnalysis : false
  const isDisabledAddress = selectedConsortium ? selectedConsortium?.digitalAccount?.pendencies.address.rejectedAt === null : false
  const isDisabledBankAndDocs = selectedConsortium ? selectedConsortium?.digitalAccount?.status !== EDigitalAccountStatus.pending && selectedConsortium?.digitalAccount?.status !== EDigitalAccountStatus.inAnalysis && selectedConsortium?.digitalAccount?.status !== EDigitalAccountStatus.disapproved : false

  const { refetch: refetchConsortiumGet, loading } = useConsortiumGetQuery({
    variables: {
      id: id || ''
    },
    skip: Boolean(!id),
    onCompleted(data) {
      setSelectedConsortium(data.consortiumGet as ConsortiumData)
    },
    onError: () => {
      toast.error('Não foi possível carregar este consórcio.')
    }
  })

  useEffect(() => {
    if (id) {
      refetchConsortiumGet()
    }
    setSelectedConsortium(null)
  }, [id])

  const [createConsortium, { loading: createIsLoading }] = useConsortiumCreateMutation({
    onCompleted: () => {
      toast.success('Um novo consórcio foi cadastrado!')
      navigate('/app/consortiums')
    },
    onError: (error) => {
      if (error.message.includes('iugu')) {
        toast.error('Os dados bancários são inválidos.')
      } else {
        toast.error('Ocorreu um erro ao cadastrar um novo consórcio.')
      }
    }
  })
  const [updateConsortium, { loading: updateIsLoading }] = useConsortiumUpdateMutation({
    onCompleted: (data) => {
      setSelectedConsortium(null)
      toast.success(`O consórco ${data.consortiumUpdate.name} foi alterado!`)
      navigate(-1)
    },
    onError: () => {
      toast.error('Ocorreu um erro ao alterar o consórcio.')
    }
  })

  const onSubmit = useCallback(async (data: ConsortiumFormData) => {

    const { filesToUpload, typesToUpload } = assembleFilesToUpload(data)
    const responseS3 = await uploadMultiToIuguWallet(filesToUpload, typesToUpload)

    const formatedResponse: ConsortiumDigitalAccountDocumentCreateDto[] = responseS3.map(item => {
      return {
        file: {
          fileName: item.file.fileName,
          fileUrl: item.file.fileUrl,
          mimeType: item.file.mimetype,
          key: item.file.key,
          storageService: item.file.storageService as EStorageService
        },
        type: item.type

      }
    })

    if (id && selectedConsortium) {
      updateConsortium({
        variables: {
          params:
          {
            _id: id,
            email: data.email,
            fantasyName: data.fantasyName,
            leaderName: data.leaderName,
            leaderEmail: data.leaderEmail,
            ...data.name === selectedConsortium?.name ? {} : { name: data.name },
            status: data.status as EConsortiumStatus,
            cnpj: clearString(data.cnpj),
            phone: data.phone && clearString(data.phone),
            address: {
              ...data.address,
              zipcode: clearString(data.address.zipcode)
            },
            documents: formatedResponse
          }
        }
      })
    } else {
      createConsortium({
        variables: {
          params: {
            email: data.email,
            fantasyName: data.fantasyName,
            leaderName: data.leaderName,
            leaderEmail: data.leaderEmail,
            name: data.name,
            status: data.status as EConsortiumStatus,
            bank: {
              account: data.bank.account,
              agency: data.bank.agency,
              code: data.bank.code,
              type: data.bank.type as EBankAccountType
            },
            cnpj: clearString(data.cnpj),
            phone: data.phone && clearString(data.phone),
            legalRepresentative: {
              name: data?.legalRepresentative?.name || '',
              document: clearString(data.legalRepresentative?.document || '')
            },
            address: {
              ...data.address,
              zipcode: clearString(data.address.zipcode)
            },
            documents: formatedResponse
          }
        }
      })
    }
  }, [selectedConsortium])

  const onSearchCep = useCallback(async () => {
    if (address?.zipcode) {
      const { data, isSuccess } = await fetchCep(address?.zipcode)
      if (isSuccess) {
        formMethods.setValue('address.country', 'Brasil')
        formMethods.setValue('address.state', data?.uf || '')
        formMethods.setValue('address.city', data?.localidade || '')
        formMethods.setValue('address.neighborhood', data?.bairro || '')
        formMethods.setValue('address.street', data?.logradouro || '')
      }
    }
  }, [address])

  useEffect(() => {
    if (id && selectedConsortium) {
      formMethods.setValue('name', selectedConsortium?.name)
      formMethods.setValue('fantasyName', selectedConsortium?.fantasyName)
      formMethods.setValue('cnpj', selectedConsortium?.cnpj)
      formMethods.setValue('email', selectedConsortium?.email)
      formMethods.setValue('status', selectedConsortium?.status || EConsortiumStatus.inactive)
      formMethods.setValue('phone', selectedConsortium?.phone || '')
      formMethods.setValue('leaderName', selectedConsortium?.leaderName)
      formMethods.setValue('leaderEmail', selectedConsortium?.leaderEmail)
      formMethods.setValue('address.zipcode', selectedConsortium?.address.zipcode)
      formMethods.setValue('address.country', selectedConsortium?.address.country)
      formMethods.setValue('address.state', selectedConsortium?.address.state)
      formMethods.setValue('address.city', selectedConsortium?.address.city)
      formMethods.setValue('address.neighborhood', selectedConsortium?.address.neighborhood)
      formMethods.setValue('address.street', selectedConsortium?.address.street)
      formMethods.setValue('address.number', selectedConsortium?.address.number)
      formMethods.setValue('address.complement', selectedConsortium?.address.complement || '')
      formMethods.setValue('legalRepresentative.name', selectedConsortium.legalRepresentative?.name || '')
      formMethods.setValue('legalRepresentative.document', selectedConsortium.legalRepresentative?.document || '')

      if (selectedConsortium.bank) {
        formMethods.setValue('bank', selectedConsortium.bank)
      }
    } else {
      formMethods.reset()
    }
  }, [selectedConsortium, id])

  useEffect(() => {
    if (id && selectedConsortium) {
      if (selectedConsortium.digitalAccount?.pendencies.address.rejectedAt) {
        formMethods.setError('address.city', { message: 'Endereço não encontrado. Verifique e tente novamente.' })
        formMethods.setError('address.complement', { message: 'Endereço não encontrado. Verifique e tente novamente.' })
        formMethods.setError('address.country', { message: 'Endereço não encontrado. Verifique e tente novamente.' })
        formMethods.setError('address.neighborhood', { message: 'Endereço não encontrado. Verifique e tente novamente.' })
        formMethods.setError('address.number', { message: 'Endereço não encontrado. Verifique e tente novamente.' })
        formMethods.setError('address.state', { message: 'Endereço não encontrado. Verifique e tente novamente.' })
        formMethods.setError('address.street', { message: 'Endereço não encontrado. Verifique e tente novamente.' })
        formMethods.setError('address.zipcode', { message: 'Endereço não encontrado. Verifique e tente novamente.' })
      }
      if (selectedConsortium.digitalAccount?.pendencies.agency.rejectedAt) {
        formMethods.setError('bank.agency', { message: 'Agência informada não existe. Verifique e tente novamente.' })
      }
      if (selectedConsortium.digitalAccount?.pendencies.account.rejectedAt) {
        formMethods.setError('bank.account', { message: 'Conta informada não existe. Verifique e tente novamente.' })
      }
    }
  }, [selectedConsortium])

  if (!canCreateAndEditConsortiums) {
    return <Navigate replace to='/app' />
  }

  if (id && loading) return <FullPageLoader />

  // console.log(selectedConsortium)

  return (
    <Box sx={{ paddingBottom: '2rem' }}>
      <Card sx={{ background: 'white', padding: '0 1rem', borderRadius: '1rem', paddingBottom: '2rem' }}>
        <ContentTitle
          title={`${id && selectedConsortium ? `Alterar o consórcio ${selectedConsortium.name}` : 'Cadastrar consórcio'}`}
          description={`Complete os dados para ${id && selectedConsortium ? 'alterar' : 'cadastrar um'} Consórcio`}
          breadcrumbLinks={{ currentLink: `${id && selectedConsortium ? 'Alterar' : 'Cadastrar'} Consórcio`, links: [{ href: '/app/consortiums', label: 'Consórcios' }] }}
        />
        {selectedConsortium?.digitalAccount?.feedback && (
          <Box sx={{ paddingBottom: '1rem' }}>
            <Banner text={selectedConsortium?.digitalAccount?.feedback} type='error' />
          </Box>
        )}
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
            <Box display='flex' flexDirection='column' gap='1rem'>
              <Typography fontWeight='1.05rem' variant='h6'>Dados básicos</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={8}>
                  <Input name='name' label='Razão social' disabled={isDisabledField} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Input disabled={Boolean(id && selectedConsortium?._id) || isDisabledField} mask='cnpj' name='cnpj' label='CNPJ' />
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <Input name='fantasyName' label='Nome fantasia' disabled={isDisabledField} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Input mask='phone' name='phone' label='Telefone' disabled={isDisabledField} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Input name='email' label='E-mail' disabled={isDisabledField} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Select options={[{ value: 'active', label: 'Ativo' }, { value: 'inactive', label: 'Inativo' }]} name='status' label='Status' disabled={isDisabledField} />
                </Grid>
                <Grid sx={{ paddingTop: '0px !important' }} item xs={12} md={4} />
                <Grid item xs={12} sm={6} md={8}>
                  <Input name='leaderName' label='Nome do consorciado lider' disabled={isDisabledField} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Input name='leaderEmail' label='E-mail do consorciado lider' disabled={isDisabledField} />
                </Grid>

                <Grid item xs={12} sm={6} md={8}>
                  <Input disabled={Boolean(id && selectedConsortium?._id)} name='legalRepresentative.name' label='Nome do representante legal' />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Input disabled={Boolean(id && selectedConsortium?._id)} mask='cpf' name='legalRepresentative.document' label='CPF do representante legal' />
                </Grid>

              </Grid>
              <Box sx={{ display: 'flex', flexFlow: 'column', gap: '1.4rem' }}>
                <Typography paddingTop='1rem' fontWeight='1.05rem' variant='h6'>Endereço</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input
                      icons={{ end: { element: fetchCepIsLoading ? <FullPageLoader /> : <IconButton onClick={() => onSearchCep()}><IconSearch /></IconButton> } }}
                      mask='cep'
                      name='address.zipcode'
                      label='CEP'
                      disabled={isDisabledAddress && isDisabledField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input name='address.country' label='País' disabled={isDisabledAddress && isDisabledField} />
                  </Grid>

                  <Grid sx={{ paddingTop: '0px !important' }} item xs={12} />
                  <Grid item xs={12} sm={6} md={4}>
                    <Input name='address.street' label='Rua' disabled={isDisabledAddress && isDisabledField} />

                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input name='address.number' label='Número' disabled={isDisabledAddress && isDisabledField} />

                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input name='address.complement' label='Complemento' disabled={isDisabledAddress && isDisabledField} />

                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input name='address.neighborhood' label='Bairro' disabled={isDisabledAddress && isDisabledField} />

                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input name='address.city' label='Cidade' disabled={isDisabledAddress && isDisabledField} />

                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Select options={brazilStates} name='address.state' label='UF' disabled={isDisabledAddress && isDisabledField} />

                  </Grid>

                </Grid>

                {/* {Boolean(!id && !selectedConsortium?._id) && ( */}
                <>
                  <Typography paddingTop='1rem' fontWeight='1.05rem' variant='h6'>Dados da conta digital</Typography>
                  <Typography fontWeight='500'>Dados da conta</Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <Select disabled={isDisabledBankAndDocs} label='Banco' name='bank.code' options={bankOptions} />
                    </Grid>
                    <Grid item xs={0} md={4} />
                    <Grid item xs={0} md={4} />

                    <Grid item xs={12} md={4}>
                      <Input disabled={isDisabledBankAndDocs} name='bank.agency' label={agencyLabel} customMask={agencyMask} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Input customMask={accountMask} disabled={isDisabledBankAndDocs} name='bank.account' label={accountLabel} />
                    </Grid>

                    <Grid item xs={12}>
                      <Radio disabled={isDisabledBankAndDocs} row name='bank.type' options={bankAccountTypeOptions} />
                    </Grid>
                  </Grid>
                  <Typography fontWeight='500'>Documentos</Typography>
                  <Grid spacing={3} container>
                    <Grid item md={6} xs={12}>
                      <DragAndDrop
                        label='Anexar selfie do representante legal'
                        name='selfie'
                        fileDescription='(JPG, JPEG, PNG, PDF.)'
                        $variant='secondary'
                        sentAt={selectedConsortium?.digitalAccount?.pendencies.selfie.inAnalysisAt || selectedConsortium?.digitalAccount?.pendencies.selfie.approvedAt}
                        $completed={Boolean(selectedConsortium?.digitalAccount?.pendencies.selfie.inAnalysisAt || selectedConsortium?.digitalAccount?.pendencies.selfie.approvedAt)}
                        fileName={consortiumSelfieLegalRepresentative?.file?.fileName}
                        fileUrl={consortiumSelfieLegalRepresentative?.file?.fileUrl}
                        fileKey={consortiumSelfieLegalRepresentative?.file.key}
                        disabled={isDisabledBankAndDocs}
                        status={getStatus(selectedConsortium?.digitalAccount?.pendencies.selfie as DigitalAccountPendecyDto)}
                        accepted={{
                          images: true,
                          pdf: true
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <DragAndDrop
                        $variant='secondary'
                        label='Anexar contrato social'
                        name='social_contract'
                        fileDescription='(JPG, JPEG, PNG, PDF.)'
                        sentAt={selectedConsortium?.digitalAccount?.pendencies.social_contract.inAnalysisAt || selectedConsortium?.digitalAccount?.pendencies.social_contract.approvedAt}
                        $completed={Boolean(selectedConsortium?.digitalAccount?.pendencies.social_contract.inAnalysisAt || selectedConsortium?.digitalAccount?.pendencies.social_contract.approvedAt)}
                        fileName={consortiumSocialContract?.file?.fileName}
                        fileUrl={consortiumSocialContract?.file?.fileUrl}
                        fileKey={consortiumSocialContract?.file.key}
                        disabled={isDisabledBankAndDocs}
                        status={getStatus(selectedConsortium?.digitalAccount?.pendencies.social_contract as DigitalAccountPendecyDto)}
                        accepted={{
                          images: true,
                          pdf: true
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid spacing={3} container>
                    <Grid item md={6} xs={12}>
                      <DragAndDrop
                        label='Anexar comprovante de endereço'
                        name='addressProof'
                        fileDescription='(JPG, JPEG, PNG, PDF.)'
                        $variant='secondary'
                        sentAt={selectedConsortium?.digitalAccount?.pendencies.addressProof.inAnalysisAt || selectedConsortium?.digitalAccount?.pendencies.addressProof.approvedAt}
                        $completed={Boolean(selectedConsortium?.digitalAccount?.pendencies.addressProof.inAnalysisAt || selectedConsortium?.digitalAccount?.pendencies.addressProof.approvedAt)}
                        fileName={consortiumAddressProof?.file?.fileName}
                        fileUrl={consortiumAddressProof?.file?.fileUrl}
                        fileKey={consortiumAddressProof?.file.key}
                        disabled={isDisabledBankAndDocs}
                        status={getStatus(selectedConsortium?.digitalAccount?.pendencies.addressProof as DigitalAccountPendecyDto)}
                        accepted={{
                          images: true,
                          pdf: true
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <DragAndDrop
                        $variant='secondary'
                        label='Anexar relatório financeiro'
                        name='balance_sheet'
                        fileDescription='(JPG, JPEG, PNG, PDF.)'
                        sentAt={selectedConsortium?.digitalAccount?.pendencies.balance_sheet.inAnalysisAt || selectedConsortium?.digitalAccount?.pendencies.balance_sheet.approvedAt}
                        $completed={Boolean(selectedConsortium?.digitalAccount?.pendencies.balance_sheet.inAnalysisAt || selectedConsortium?.digitalAccount?.pendencies.balance_sheet.approvedAt)}
                        fileName={consortiumBalanceSheet?.file?.fileName}
                        fileUrl={consortiumBalanceSheet?.file?.fileUrl}
                        fileKey={consortiumBalanceSheet?.file.key}
                        disabled={isDisabledBankAndDocs}
                        status={getStatus(selectedConsortium?.digitalAccount?.pendencies.balance_sheet as DigitalAccountPendecyDto)}
                        accepted={{
                          images: true,
                          pdf: true
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Box sx={{ display: 'flex', flexFlow: 'column', gap: '0.4rem' }}>

                    <Box sx={{ display: 'flex', gap: '0.4rem', alignItems: 'center' }}>
                      <IconExclamationCircle color='#F3CC04' /><Typography variant='body1'> O documento de identificação deve conter o CPF do representante legal!</Typography>
                    </Box>
                  </Box>
                  <Grid spacing={3} container>
                    <Grid item md={6} xs={12}>
                      <DragAndDrop
                        label='Anexar RG, CNH ou Passaporte - representante legal (frente)'
                        name='identificationFront'
                        fileDescription='(JPG, JPEG, PNG.)'
                        $variant='secondary'
                        sentAt={selectedConsortium?.digitalAccount?.pendencies.identificationFront.inAnalysisAt || selectedConsortium?.digitalAccount?.pendencies.identificationFront.approvedAt}
                        $completed={Boolean(selectedConsortium?.digitalAccount?.pendencies.identificationFront.inAnalysisAt || selectedConsortium?.digitalAccount?.pendencies.identificationFront.approvedAt)}
                        fileName={consortiumIdentificationFront?.file?.fileName}
                        fileUrl={consortiumIdentificationFront?.file?.fileUrl}
                        fileKey={consortiumIdentificationFront?.file.key}
                        disabled={isDisabledBankAndDocs}
                        status={getStatus(selectedConsortium?.digitalAccount?.pendencies.identificationFront as DigitalAccountPendecyDto)}
                        accepted={{
                          images: true,
                          pdf: false
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <DragAndDrop
                        $variant='secondary'
                        label='Anexar RG, CNH ou Passaporte - representante legal (verso)'
                        name='identificationBack'
                        fileDescription='(JPG, JPEG, PNG.)'
                        sentAt={selectedConsortium?.digitalAccount?.pendencies.identificationBack.inAnalysisAt || selectedConsortium?.digitalAccount?.pendencies.identificationBack.approvedAt}
                        $completed={Boolean(selectedConsortium?.digitalAccount?.pendencies.identificationBack.inAnalysisAt || selectedConsortium?.digitalAccount?.pendencies.identificationBack.approvedAt)}
                        fileName={consortiumIdentificationBack?.file?.fileName}
                        fileUrl={consortiumIdentificationBack?.file?.fileUrl}
                        fileKey={consortiumIdentificationBack?.file.key}
                        disabled={isDisabledBankAndDocs}
                        status={getStatus(selectedConsortium?.digitalAccount?.pendencies.identificationBack as DigitalAccountPendecyDto)}
                        accepted={{
                          images: true,
                          pdf: false
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
                {/* )} */}

              </Box>

              {/* {Boolean(id && selectedConsortium?._id) && ( */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '.6rem', padding: '.6rem 0 1rem 0' }}>
                <Typography color={theme.palette.black.light} fontWeight={500}>Status da conta digital:</Typography>
                <DigitalAccountStatus status={selectedConsortium?.digitalAccount?.status} />
              </Box>
              {/* )} */}

              <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexFlow: isLowerSm ? 'column-reverse' : 'row',
                gap: isLowerSm ? '1rem' : '0'
              }}>

                <Link style={{ width: '100%', maxWidth: isLowerSm ? '100%' : '160px' }} to='/app/consortiums'>
                  <Button sx={{ maxWidth: isLowerSm ? '100%' : '160px' }} fullWidth disabled={createIsLoading || updateIsLoading} color='secondary'>
                    Cancelar
                  </Button>
                </Link>
                <Button
                  startIcon={<IconCheck />}
                  sx={{ maxWidth: isLowerSm ? '100%' : '220px' }}
                  fullWidth
                  disabled={createIsLoading || updateIsLoading || isDisabledField}
                  type='submit'>
                  {id && selectedConsortium ? 'Alterar' : 'Cadastrar'} Consórcio
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Card>
    </Box>
  )
}
