/* eslint-disable no-unused-vars */
import { Column, TableInstance } from 'react-table'
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Table as MuiTable,
  Paper, Select,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery
} from '@mui/material'
import { IconArrowUp, IconArrowDown, IconChevronRight, IconChevronLeft, IconChevronsRight, IconChevronsLeft } from '@tabler/icons-react'
import { theme } from '~/utils'
import { CSSProperties, useEffect } from 'react'
import { useApiPaginationV2, usePaginationValues } from '~/hooks'
import { FullPageLoader } from '../FullPageLoader'
import { isEmpty } from 'ramda'
import { useSearchParams } from 'react-router-dom'

type TableApiProps = {
  columns: Column[],
  tableMethods: TableInstance
  isLoading?: boolean
  options?: {
    clearRowSelectionOnPagination?: boolean,
    itemsPerPageValues?: number[]
    lastColumnAlignRight?: boolean,
  }
  tableLayout?: CSSProperties['tableLayout'],
  backgroundColor?: string
}

const defaultOptions = {
  clearRowSelectionOnPagination: false,
  itemsPerPageValues: [10, 20, 30, 40, 50],
  lastColumnAlignRight: true
}

export function TableApiV2({ tableMethods, options = defaultOptions, isLoading = false, tableLayout = 'auto', backgroundColor = 'white' }: TableApiProps) {
  const paginationValues = useApiPaginationV2()
  const [, setSearchParams] = useSearchParams()

  const {
    data,
    getTableProps,
    headerGroups,
    prepareRow,
    getTableBodyProps,
    page,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex }
  } = tableMethods

  useEffect(() => {
    if (options?.clearRowSelectionOnPagination && selectedFlatRows.length !== 0) {
      toggleAllRowsSelected(false)
    }
  }, [pageIndex])

  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <TableContainer sx={{ boxShadow: 'none', backgroundColor }} component={Paper}>
        <MuiTable sx={{ tableLayout: 'auto', width: '100%' }}  {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
                {headerGroup.headers.map((column, i) => (
                  <TableCell
                    {...column.getHeaderProps(column?.getSortByToggleProps())}
                    key={column.getHeaderProps().key}
                    align={headerGroup.headers.length === i + 1 && options.lastColumnAlignRight ? 'right' : 'left'}
                    sx={{ width: column.id === 'selection' ? '30px' : 'auto', color: theme.palette.grey[400], fontWeight: 600, padding: '1rem 0' }}
                  >
                    {column.render('Header')}
                    {column?.isSorted && (
                      <>
                        {column?.isSortedDesc ?
                          <IconArrowDown size={18} color={theme.palette.grey[500]} />
                          :
                          <IconArrowUp size={18} color={theme.palette.grey[500]} />
                        }
                      </>
                    )}
                    {column.canFilter ? column.render('Filter') : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {isLoading && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={headerGroups && headerGroups[0].headers.length}>
                  <FullPageLoader sx={{ height: '60px' }} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!isLoading && data && (
            <TableBody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                return (
                  <TableRow {...row.getRowProps()} key={row.getRowProps().key}>
                    {row.cells.map((cell, i) => {
                      return (
                        <TableCell
                          align={row.cells.length === i + 1 && options.lastColumnAlignRight ? 'right' : 'left'}
                          padding={row.cells.find(item => item.column.id === 'selection')?.column.id === 'selection' ? 'checkbox' : 'normal'}
                          {...cell.getCellProps()}
                          key={cell.getCellProps().key}
                          width={row.cells.length === i + 1 && options.lastColumnAlignRight ? `20px` : 'auto'}
                          sx={{ padding: '.4rem 0' }}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          )}
          {!isLoading && isEmpty(data) && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={headerGroups && headerGroups[0].headers.length}>
                  <Typography color='grey.400' align='center'>Não há dados há serem exibidos</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {!isLoading && !isEmpty(data) && (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={99}>
                  <Box gap='1rem' flexDirection={isLowerSm ? 'column' : 'row'} display='flex' width='100%' justifyContent='space-between'>
                    <Box display='flex' alignItems='center' gap={1}>
                      <Typography>Linhas por página</Typography>
                      <FormControl size='small'>
                        <Select
                          disabled={isEmpty(data)}
                          defaultValue={10}
                          value={paginationValues.size}
                          onChange={(e) => {
                            setSearchParams(state => {
                              state.set('size', String(e.target.value))
                              state.set('page', '0')
                              return state
                            })
                          }}>
                          {options.itemsPerPageValues?.map((optionSize) => (
                            <MenuItem key={optionSize} value={optionSize}>
                              {optionSize}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box display='flex' alignItems='center' gap={0}>
                      <Typography fontWeight={600} display='flex' paddingRight={2}>
                        {paginationValues.page + 1} - {Math.ceil((paginationValues.items || 0) / paginationValues.size)}
                      </Typography>
                      <IconButton
                        onClick={() => paginationValues.firstPage()}
                        disabled={paginationValues.canPrevPage}
                        aria-label='previous page'
                      >
                        {theme.direction === 'rtl' ? <IconChevronsLeft /> : <IconChevronsLeft />}
                      </IconButton>
                      <IconButton
                        onClick={() => paginationValues.prevPage()}
                        disabled={paginationValues.canPrevPage}
                        aria-label='previous page'
                      >
                        {theme.direction === 'rtl' ? <IconChevronLeft /> : <IconChevronLeft />}
                      </IconButton>
                      <IconButton
                        onClick={() => paginationValues.nextPage()}
                        disabled={paginationValues.canNextPage}
                        aria-label='previous page'
                      >
                        {theme.direction === 'rtl' ? <IconChevronRight /> : <IconChevronRight />}
                      </IconButton>
                      <IconButton
                        onClick={() => paginationValues.lastPage()}
                        disabled={paginationValues.canNextPage}
                        aria-label='previous page'
                      >
                        {theme.direction === 'rtl' ? <IconChevronsRight /> : <IconChevronsRight />}
                      </IconButton>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </MuiTable>
      </TableContainer>
    </>
  )
}
