import { Grid, Typography, useMediaQuery } from '@mui/material'
import { DragAndDrop } from '~/components/Form/DragAndDrop'
import { ECustomerPersonType, EProposalFileStatus, ProposalFileModel } from '~/graphql/types'
import { theme } from '~/utils'

export type PersonTypeDocumentsProps = {
  personType?: ECustomerPersonType
  customerFrontDocument?: ProposalFileModel
  customerBackDocument?: ProposalFileModel
  customerPjDocumentCardDocument?: ProposalFileModel
  customerPjSocialContractDocument?: ProposalFileModel
  disabled?: boolean
}
export const PersonTypeDocuments: React.FC<PersonTypeDocumentsProps> = (props) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const { personType, customerBackDocument, customerFrontDocument, customerPjDocumentCardDocument, customerPjSocialContractDocument } = props

  if (personType === ECustomerPersonType.pf) {
    return (
      <>
        <Typography fontSize={isLowerSm ? '1rem' : '1.4rem'} variant='h3'>Documento de identificação</Typography>
        <Grid spacing={3} container>
          <Grid item xs={12} md={6}>
            <DragAndDrop
              disabled={props.disabled}
              label='Anexar RG ou CNH (Frente)'
              name='customerPfDocumentWithPhotoFront'
              fileDescription='(JPG, JPEG, PNG. Tamanho máximo 15MB)'
              $variant='secondary'
              sentAt={customerFrontDocument?.sendAt}
              status={customerFrontDocument?.status as EProposalFileStatus}
              $completed={customerFrontDocument?.sendAt}
              fileName={customerFrontDocument?.file?.fileName}
              fileUrl={customerFrontDocument?.file?.fileUrl}
              accepted={{
                images: true,
                pdf: false
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DragAndDrop
              disabled={props.disabled}
              $variant='secondary'
              label='Anexar RG ou CNH (Verso)'
              name='customerPfDocumentWithPhotoBack'
              fileDescription='(JPG, JPEG, PNG. Tamanho máximo 15MB)'
              status={customerBackDocument?.status as EProposalFileStatus}
              sentAt={customerBackDocument?.sendAt}
              $completed={customerBackDocument?.sendAt}
              fileName={customerBackDocument?.file?.fileName}
              fileUrl={customerBackDocument?.file?.fileUrl}
              accepted={{
                images: true,
                pdf: false
              }}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Typography fontSize={isLowerSm ? '1rem' : '1.4rem'} variant='h3'>Contrato social</Typography>
      <DragAndDrop
        disabled={props.disabled}
        label='Anexar contrato social'
        name='customerPjSocialContract'
        fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
        $variant='secondary'
        sentAt={customerPjSocialContractDocument?.sendAt}
        status={customerPjSocialContractDocument?.status as EProposalFileStatus}
        $completed={customerPjSocialContractDocument?.sendAt}
        fileName={customerPjSocialContractDocument?.file?.fileName}
        fileUrl={customerPjSocialContractDocument?.file?.fileUrl}
      />

      <Typography fontSize={isLowerSm ? '1rem' : '1.4rem'} variant='h3'>Cartão CNPJ</Typography>
      <DragAndDrop
        disabled={props.disabled}
        label='Anexar cartão CNPJ'
        name='customerPjDocumentCard'
        fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
        $variant='secondary'
        sentAt={customerPjDocumentCardDocument?.sendAt}
        status={customerPjDocumentCardDocument?.status as EProposalFileStatus}
        $completed={customerPjDocumentCardDocument?.sendAt}
        fileName={customerPjDocumentCardDocument?.file?.fileName}
        fileUrl={customerPjDocumentCardDocument?.file?.fileUrl}
      />
    </>
  )
}
