import { Box, Grid, Typography } from '@mui/material'
import { DragAndDrop } from '~/components/Form/DragAndDrop'
import { CustomerModel, ECustomerFileType, ECustomerPersonType } from '~/graphql/types'

export type DocumentationFieldsProps = {
  personType?: ECustomerPersonType
  disableFields?: boolean
  currentCustomer: CustomerModel | null
}
export const DocumentationFields: React.FC<DocumentationFieldsProps> = (props) => {
  const { personType, disableFields, currentCustomer } = props

  const customerFrontDocument = currentCustomer?.files?.find(file => file.type === ECustomerFileType.customerPfDocumentWithPhotoBack)
  const customerBackDocument = currentCustomer?.files?.find(file => file.type === ECustomerFileType.customerPfDocumentWithPhotoFront)

  const customerPjDocumentCardDocument = currentCustomer?.files?.find(file => file.type === ECustomerFileType.customerPjDocumentCard)
  const customerPjSocialContractDocument = currentCustomer?.files?.find(file => file.type === ECustomerFileType.customerPjSocialContract)

  if (personType === ECustomerPersonType.pf) {
    return (
      <>
        <Typography variant='h3' fontWeight={500}>Documentação</Typography>
        <Grid spacing={3} container>
          <Grid item md={6} xs={12}>
            <DragAndDrop
              disabled={disableFields}
              label='Anexar RG ou CNH (Frente)'
              name='customerPfDocumentWithPhotoFront'
              fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
              $variant='secondary'
              sentAt={customerFrontDocument?.sendAt}
              $completed={Boolean(customerFrontDocument?.sendAt)}
              fileName={customerFrontDocument?.file?.fileName}
              fileKey={customerFrontDocument?.file?.key}
              accepted={{
                images: true,
                pdf: false
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DragAndDrop
              disabled={disableFields}
              $variant='secondary'
              label='Anexar RG ou CNH (Verso)'
              name='customerPfDocumentWithPhotoBack'
              sentAt={customerBackDocument?.sendAt}
              $completed={Boolean(customerBackDocument?.sendAt)}
              fileKey={customerBackDocument?.file?.key}
              fileName={customerBackDocument?.file?.fileName}
              fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
              accepted={{
                images: true,
                pdf: false
              }}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Typography variant='h3' fontWeight={500}>Documentação</Typography>
      <Box>
        <Typography variant='body2' fontWeight={500} marginBottom={2}>Contrato social</Typography>
        <DragAndDrop
          disabled={disableFields}
          label='Anexar contrato social'
          name='customerPjSocialContract'
          fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
          $variant='secondary'
          sentAt={customerPjSocialContractDocument?.sendAt}
          $completed={Boolean(customerPjSocialContractDocument?.sendAt)}
          fileUrl={customerPjSocialContractDocument?.file?.fileUrl}
          fileName={customerPjSocialContractDocument?.file?.fileName}
        />
      </Box>

      <Box>
        <Typography variant='body2' fontWeight={500} marginBottom={2}>Cartão CNPJ</Typography>
        <DragAndDrop
          disabled={disableFields}
          label='Anexar cartão CNPJ'
          name='customerPjDocumentCard'
          fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
          $variant='secondary'
          sentAt={customerPjDocumentCardDocument?.sendAt}
          $completed={Boolean(customerPjDocumentCardDocument?.sendAt)}
          fileUrl={customerPjDocumentCardDocument?.file?.fileUrl}
          fileName={customerPjDocumentCardDocument?.file?.fileName}
        />
      </Box>
    </>
  )
}
