import { createConfirmation } from 'react-confirm'
import { MyDialogProps, ConfirmDialog } from './MyDialog'

const confirm = createConfirmation(ConfirmDialog)

export function confirmDialog({
  title, 
  cancelText,
  proccedText,
  type,
  content
}: MyDialogProps) {
  return confirm({ 
    title, 
    cancelText,
    proccedText,
    type,
    content
  })
}
