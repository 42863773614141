import { PropsWithChildren } from 'react'

export type FormProps = React.JSX.IntrinsicElements['form']
export const Form: React.FC<PropsWithChildren<FormProps>> = ({ children, ...rest }) => {
  return (
    <form style={{ display: 'flex', flexDirection: 'column', width: '100%' }} {...rest}>
      {children}
    </form>
  )
}
