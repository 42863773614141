/* eslint-disable no-unused-vars */
import { ArrowBack, ArrowForward, Save } from '@mui/icons-material'
import { ProposalFlowActionDropdown, PropsWithWizard } from '~/components'
import { Box, CircularProgress, Divider, useMediaQuery } from '@mui/material'
import { Button } from '~/components/Form/Button'
import { useProposalContext } from '~/contexts'
import { EProposalStatus, ProposalModel, useProposalFormalizationMutation } from '~/graphql/types'
import { usePermission } from '~/hooks'
import { theme } from '~/utils'
import { toast } from 'react-toastify'
import { useMemo } from 'react'
import { getCanFormalizeProposal } from '~/utils/proposal'

type ActionButtonsProps = {
  loading: boolean
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}
export const ActionButtons: React.FC<PropsWithWizard<ActionButtonsProps>> = (props) => {
  const { proposal, setProposal } = useProposalContext()
  const { onNext, onPrev, loading, onSubmit } = props

  const disableFields = proposal?.status !== EProposalStatus.documentation
  const canFormalizeProposal = useMemo(() => getCanFormalizeProposal(proposal, true), [proposal])
  const canEditProposal = usePermission(['proposals.updated'])
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const [proposalFormalization, { loading: proposalFormalizationIsLoading }] = useProposalFormalizationMutation({
    variables: {
      proposalId: proposal?._id || ''
    },
    onError(error) {
      toast.error(error.message)
    },
    onCompleted(data) {
      if(setProposal && data.proposalFormalization.termOfAdhesion) {
        setProposal({ ...proposal , ...data.proposalFormalization } as ProposalModel)
        onNext()
      }
    },
  })

  const handleSubmit = async (submitType: 'continue' | 'save') => {
    if (submitType === 'continue') {
      await proposalFormalization()
    } else {
      await onSubmit()
    }
  }

  return (
    <>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

      <Box sx={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
        {!isLowerMd && (
          <Button disabled={loading} startIcon={<ArrowBack />} color='secondary' onClick={onPrev}>
            Voltar
          </Button>
        )}

        <Box sx={{ display: 'flex', flexDirection: isLowerSm ? 'column' : 'row', gap: 2, width: '100%' }}>
          <Box sx={{ display: 'flex', gap: 2, width: '100%', justifyContent: isLowerSm ? 'space-between' : 'flex-end' }}>
            <ProposalFlowActionDropdown disabled={loading || disableFields} proposal={proposal} />

            <Button
              onClick={() => handleSubmit('save')}
              disabled={loading || disableFields || !canEditProposal || proposalFormalizationIsLoading}
              startIcon={loading ? <CircularProgress size={24} color='inherit' /> : <Save />}
              color='secondary'
              size='small'
            >
              Gravar dados
            </Button>

          </Box>
          <Button
            fullWidth={isLowerSm}
            disabled={loading || disableFields || !canEditProposal || proposalFormalizationIsLoading || !canFormalizeProposal}
            endIcon={<ArrowForward />}
            onClick={() => handleSubmit('continue')}
          >
            Formalizar
          </Button>
        </Box>
      </Box>
    </>
  )
}
