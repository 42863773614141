import { Box, IconButton, Typography, useMediaQuery } from '@mui/material'
import { ContractInfo, FilterButton, InvoiceStatus, TableApiV2, TableHeaderApi } from '~/components'
import { useContract } from '~/contexts'
import { InvoiceListDrawer } from './components'
import { SearchQueysData, useQueryString, useToggle } from '~/hooks'
import { useFilters, useRowSelect, useSortBy, useTable, usePagination, Column } from 'react-table'
import { useMemo } from 'react'
import { PaymentModel, useInvoiceListQuery } from '~/graphql/types'
import { IconDownload } from '@tabler/icons-react'
import { Link } from 'react-router-dom'
import { addHours, formatDate, formatMoney, monthNameByMonthNumber, transformMoney } from '~/utils'
import { useTheme } from '@emotion/react'

export const Invoices: React.FC = () => {
  const theme = useTheme()
  const { contract } = useContract()

  const isLowerLg = useMediaQuery(theme.breakpoints.down('lg'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const queryKeys: SearchQueysData[] = [
    { key: 'status', defaultValue: [], parseValue: true },
    { key: 'by', defaultValue: [], parseValue: true },
    { key: 'begin', defaultValue: '' },
    { key: 'end', defaultValue: '' },
    { key: 'dueBegin', defaultValue: '' },
    { key: 'dueEnd', defaultValue: '' },
  ]

  const { searchQuerys, paginationQuerySize, searchParams, setSearchParams, page, size } = useQueryString()

  const queryValues = searchQuerys(queryKeys)

  const { isTrue: drawerIsOpen, toggle: toggleDrawer, setIsTrue: setDrawerIsOpen } = useToggle()
  // const { isTrue: secondInvoiceModalIsVisible, setIsTrue: setSecondInvoiceModalIsVisible } = useToggle()
  // const { isTrue: shareInvoiceIsVisible, setIsTrue: setShareInvoiceIsVisible } = useToggle(true)

  // const cards: InvoiceCardProps[] = [
  //   {
  //     title: 'saldo de geração',
  //     description: 'KWh disponíveis para o cliente',
  //     value: '576 Kwh',
  //     icon: <IconBolt size={24} color={theme.palette.despertaPurple.main} />
  //   },
  //   {
  //     title: 'total de benefício',
  //     description: 'Soma do benefício para o cliente',
  //     value: 'R$ 1.222,63',
  //     icon: <IconPigMoney size={24} color={theme.palette.despertaPurple.main} />
  //   },
  //   {
  //     title: 'valor da desperta',
  //     description: 'Valor líquido espero para a empresa',
  //     value: 'R$ 3.020,11',
  //     icon: <IconSunHigh size={24} color={theme.palette.despertaPurple.main} />
  //   },
  //   {
  //     title: 'valor em aberto',
  //     description: 'Soma de faturas não pagas (3)',
  //     value: 'R$ 3.775,14',
  //     icon: <IconReceipt2 size={24} color={theme.palette.despertaPurple.main} />
  //   },
  // ]

  const { data, loading } = useInvoiceListQuery({
    variables: {
      params: {
        pageSize: size,
        pageNumber: page,
        contractId: contract?._id,
        sort: {
          field: 'createdAt',
          order: -1
        },
        ...queryValues.status?.length > 0 ? { inStatus: queryValues.status } : {},
        ...queryValues.by?.length > 0 ? { secondCopy: queryValues.by.map((item: string) => item === 'true' ? true : false) } : {},
        ...queryValues?.begin && queryValues?.end ?
          {
            createdAt:
            {
              startDate: new Date(queryValues.begin),
              endDate: new Date(`${queryValues.end}Z23:59:59`)
            }
          }
          : {},
        ...queryValues?.dueBegin && queryValues?.dueEnd ?
          {
            dueDate:
            {
              startDate: new Date(queryValues.dueBegin),
              endDate: new Date(`${queryValues.dueEnd}Z23:59:59`)
            }
          }
          : {},
      }
    },
    onCompleted(result) {
      setSearchParams(state => {
        state.set('items', String(result.invoiceList.meta.documentCounts))
        return state
      })
    },
    fetchPolicy: 'no-cache'
  })

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Referência',
        accessor: 'energyBill',
        Cell: (props) => <>{monthNameByMonthNumber(props.value.reference.month)}, {Number(props.value.reference.year) + 2000}</>
      },
      {
        Header: 'Data de criação',
        accessor: 'createdAt',
        Cell: (props) => <>{formatDate(props.value)}</>
      },
      {
        Header: 'Vencimento',
        accessor: 'dueDate',
        Cell: (props) => {

          const newDate = addHours(new Date(props.value), 3)
          return <>{formatDate(newDate)}</>

        }
      },
      {
        Header: 'Pagamento',
        accessor: 'payment',
        Cell: (props) => {
          const itemPaidAt = props.value.find((item: PaymentModel) => item.paidAt !== null)?.paidAt
          return (
            <> {itemPaidAt ? formatDate(itemPaidAt) : '-'}</>
          )
        }
      },
      {
        Header: 'Valor',
        accessor: 'amountCents',
        disableSortBy: true,
        Cell: (props) => <>{props.value ? formatMoney(transformMoney(props.value, 'toReal')) : '-'}</>
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        Cell: (props) => <InvoiceStatus status={props.value} />
      },
      {
        Header: 'Baixar fatura',
        accessor: '_id',
        disableSortBy: true,
        Cell: (props) => <Link target='_blank' to={`${import.meta.env.VITE_APP_REST_API_URL}customer-area/download/invoice/${props.value}`}><IconButton><IconDownload /></IconButton></Link>
      },
      // {
      //   Header: 'Ações',
      //   accessor: '_id',
      //   disableSortBy: true,
      //   Cell: (props) => <Link to={`${import.meta.env.VITE_APP_REST_API_URL}customer-area/download/${props.value}`}><IconButton><IconDots /></IconButton></Link>
      // },
    ]
  }, [])

  const dataWithMemo = useMemo(() => data?.invoiceList.data || [], [data])

  const defaultColumnHiddens = isLowerLg ?
    [''].concat(isLowerMd ? [''] : '').concat(isLowerSm ? [''] : '')
    : []

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    defaultColumn: {
      Filter: <></>
    },
    manualPagination: true,
    initialState: {
      hiddenColumns: defaultColumnHiddens
    }
  },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  )

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <ContractInfo contract={contract} />
        {/* <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, color: theme.palette.grey[800], paddingBottom: '.6rem' }}>Visão geral de faturas</Typography> */}
        {/* <GridColumn
          responsive={[{ breakWidth: theme.breakpoints.values.lg, columns: 2 }, { breakWidth: theme.breakpoints.values.sm, columns: 1 }]}
          columns={4}
          >
          {cards.map((card) => {
            return (
              <InvoiceCard {...card} key={card.title} />
              )
              })}
              </GridColumn> */}

        <TableHeaderApi
          startElement={(
            <Typography
              sx={{
                fontSize: '1.5rem',
                fontWeight: 500,
                color: theme.palette.grey[800],
              }}>
              Histórico de faturas
            </Typography>
          )}
          endElement={(
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <FilterButton
                  filtersToClear={queryKeys}
                  toggleDrawer={toggleDrawer}
                  filterCounter={searchParams.size - paginationQuerySize}
                />
              </Box>

              <InvoiceListDrawer
                setDrawerIsOpen={setDrawerIsOpen}
                queryKeys={queryKeys}
                drawerIsOpen={drawerIsOpen}
                toggleDrawer={toggleDrawer}
              />
            </>
          )} />

        <TableApiV2
          isLoading={loading}
          tableMethods={tableMethods}
          columns={columns}
        />
      </Box>
      {/* <SecondInvoiceModal isVisible={secondInvoiceModalIsVisible} setIsVisible={setSecondInvoiceModalIsVisible} />
      <ShareInvoiceModal typeShare='whatsapp' isVisible={shareInvoiceIsVisible} setIsVisible={setShareInvoiceIsVisible} /> */}

    </>
  )
}
