import { Typography, Box, useMediaQuery } from '@mui/material'
import { IconInfoCircle, IconInfoCircleFilled } from '@tabler/icons-react'
import { CSSProperties, JSX } from 'react'
import { theme } from '~/utils'

type BannerProps = {
  text: string,
  type?: 'info' | 'warning' | 'error',
  icon?: JSX.Element,
  padding?: {
    default: CSSProperties['padding'],
    sm?: CSSProperties['padding'],
  }
}

export const Banner: React.FC<BannerProps> = ({ text, icon, type = 'info', padding = { default: '1.4rem', sm: '.8rem' } }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const stylesType = {
    info: {
      color: theme.palette.info.dark,
      icon: <IconInfoCircleFilled style={{ color: theme.palette.info.dark }} size={34} />
    },
    warning: {
      color: theme.palette.yellow.main,
      icon: <IconInfoCircle size={34} color={theme.palette.yellow.main} />
    },
    error: {
      color: theme.palette.error.main,
      icon: <IconInfoCircle size={34} color={theme.palette.error.main} />
    },
  }

  const selectedStyle = stylesType[type]

  return (
    <Box sx={{
      display: 'flex',
      alignItems: isLowerSm ? 'flex-start' : 'center',
      flexFlow: isLowerSm ? 'column' : 'row',
      gap: '.8rem',
      color: theme.palette.black.main,
      border: `1px solid ${selectedStyle.color}`,
      borderRadius: '.6rem',
      padding: isLowerSm ? padding.sm : padding.default
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {icon || selectedStyle.icon}
      </Box>
      <Typography variant={isLowerSm ? 'body2' : 'body1'}>{text}</Typography>
    </Box>
  )
}
